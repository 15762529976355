@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root{
    --baseColor:#EEEEEE;
    ;
}

.App {
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #FFF;
    margin: 0;
}

.mouse-pointer{
    cursor: pointer;
}

.react-calendar{
    font-family: 'Roboto', sans-serif;
    
}

.react-calendar__navigation button{
    font-size: 1.0625rem;
    color: #5D5D5D;
    
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus{
    background: #3ADDC1 !important;
    border-radius: 0.9375rem;
    
}

.react-calendar__tile:enabled:hover, 
.react-calendar__tile:enabled:focus{
    border-radius: 0.9375rem;
    border: 1px solid #2BD8BA;
    margin-top: -1px;
    margin-bottom: -1px;
    background: transparent;
}

.react-calendar__tile--now{
    border-radius: 0.9375rem;
}

.custom-col-4.col-5 {
    flex: 0 0 auto;
    width: 37.5%;
}

.col.col-1{
    flex: 0 0 auto !important;
    width: 6.25% !important;
}

.col-1.col-2{
    flex: 0 0 auto !important;
    width: 12.5% !important;
}

.col-2.col-3{
    flex: 0 0 auto !important;
    width: 20.8333333334% !important;
}

.col-3.col-4{
    flex: 0 0 auto !important;
    width: 29.16666666667% !important;
}

.col-4.col-5{
    flex: 0 0 auto !important;
    width: 37.5% !important;
}

.col-5.col-6{
    flex: 0 0 auto !important;
    width: 45.833333333334% !important;
}

.col-6.col-7{
    flex: 0 0 auto !important;
    width: 54.166666666667% !important;
}

.col-7.col-8{
    flex: 0 0 auto !important;
    width: 62.5% !important;
}

.col-8.col-9{
    flex: 0 0 auto !important;
    width: 70.833333333334% !important;
}

.col-9.col-10{
    flex: 0 0 auto !important;
    width: 79.1666666666667% !important;
}

.col-10.col-11{
    flex: 0 0 auto !important;
    width: 87.5% !important;
}

.col-11.col-12{
    flex: 0 0 auto !important;
    width: 95.8333333333334% !important;
}

.mouse-pointer {
    cursor: pointer;
}

.container-white{
    background-color: #ffffff;
    border-radius: .3rem;
    padding: 1rem 1rem 1rem 0rem;
    height: 100%;

}
.container-cyan{
    background-color: #ade6ff;
    border-radius: .3rem;
    padding: .4rem;
    height: 100%;

}

.custom-modal{
    border-radius: 1rem;
    background: transparent;
    border: 0;
}

.custom-modal-header{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: var(--baseColor);
    padding: 1rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    color: #5D5D5D;
    border: 0;
}

.custom-modal-header .btn-close {
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%235D5D5D%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
}

.custom-modal-body{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 1rem 1rem !important;
    background: #FFF;
    border: 0;
}

.custom-modal-content{
    border-radius: 1rem;
    border: 1px solid var(--baseColor);
    padding: 1rem 1rem;
}

.border-none{
    border-style: hidden!important;
    border-width: 0px!important;
    border-color:transparent !important;
}