/* sidebar css start */
.sidebar-container{
    background: var(--baseColor);
    box-shadow: 1px 0px 6px 0px rgba(0, 0, 0, 0.25);
    min-width: 15rem;
    position: fixed;
    top: 0;
    height: 100vh;
    border: 8px solid var(--baseColor);
    border-radius: 32px;
}


.sidebar-container .logo-container{
    display: flex;
    align-items: center;
    padding-top: 1rem;
    object-fit: contain;
}

.logo-container .hospital-img-logo{
    width: 4.2rem;
    height: 3.4rem;
}

.logo-container .hospital-text-logo{
    width: 7.5rem;
    height: 1.3rem;
}

.sidebar-link{
    color: #343434!important;
    align-items: center;
    justify-content: start;
    /* width: 200px; */
    height: 40px;
    border-radius: 5px;
    
}
.sidebar-link.active{
    background: #E3B075;
    
}
.link-container.sidebar-link-title {
    word-wrap: break-word;
}

.link-container {
    overflow: hidden;
}
/* sidebar css end */