/* header css start */
.nav-container{
    height: 5.3125rem;
    /* background-image:linear-gradient(#272954, #272954), url(../../../assets/pictures/images/nav_background.jpg); */
    background-blend-mode: overlay;
    background-position: 50% 50%;
    /* background-color: #0b381b; */
    background-repeat: no-repeat;
    background-size: cover;
}

.nav-menu-container{
    background: #FFF;
    margin-top: 1.87rem;
    margin-right: 1.87rem;
    height: 2.8125rem;
    border-radius: 0.4375rem;
    display: flex;
    align-items: center;
}

.nav-profile-container{
    display: flex;
    align-items: center;
    text-align: end;
    padding: 0rem 0.31remrem 0rem 0rem;
}

.nav-profile-container .text{
    display: grid;
    justify-content: end;
    /* padding-right: 0.31rem; */
}

.nav-profile-container .text .profile-name{
    color: #232323;
    font-size: 0.71456rem;
    font-weight: 700;
    display: flex-end;
}

.nav-profile-container .text .profile-role{
    color: #232323;
    font-size: 0.6125rem;
    font-weight: 500;
    display: flex-end;
}

.nav-profile-container .img{
    padding: 0rem 0rem 0rem 0.63rem;
}

.nav-profile-container .img .profile-img-rnd{
    object-fit: contain;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 3.0625rem;
    border: 0.5px solid silver;
}

.profile-popup-container{
    margin-left: -2.25rem;
    margin-top: 0.5rem;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 0.5rem;
    display: grid;
    width: auto;
    overflow: hidden;
}

.profile-popup-content{
    cursor: pointer;
    border-radius: 0.5rem;
    width: 7rem;
    padding: 0rem 0.6rem 0rem 0.6rem;
    background: #f4f4f4;
    font-weight: 700;
    display: flex;
    align-items: center;
}
/* header css end */