.physician-details-icon{
    height: 1.25rem;
    width: 1.25rem;
}

.physician-documents-icon{
    height: 1.5rem;
    width: 1.5rem;
}

.physician-photo-upload{
    height: 12.5rem;
    border-radius: 0.3125rem;
    border: 1px solid #D0D0D0;
    background: #FFF;
}

.upload-browse-btn{
    width: 7rem;
    height: 2.5rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #6FBBEC;
    color: #FFF;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload-browse-btn:hover{
    color: #454545;
    border: 1px solid #454545;
    background-color: #ffffff;
}

.upload-clear-btn{
    width: 7rem;
    height: 2.5rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    color: #454545;
    border: 1px solid #454545;
    background-color: #ffffff;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload-clear-btn:hover{
    color: #FFF;
    background: #6FBBEC;
}

.physician-document-upload{
    height: 20rem;
    border-radius: 0.3125rem;
    border: 1px solid #D0D0D0;
    background: #FFF;
}

.upload-submit-btn{
    width: 7rem;
    height: 2.5rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #154674;
    color: #FFF;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload-submit-btn:hover{
    color: #154674;
    border: 1px solid #154674;
    background-color: #ffffff;
}

.document-clear-btn{
    width: 7rem;
    height: 2.5rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    color: #154674;
    border: 1px solid #154674;
    background-color: #ffffff;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.document-clear-btn:hover{
    color: #FFF;
    background: #154674;
}

.physician-doc-container{
    height: 26.39rem;
    border-radius: 0.3125rem;
    background: #FFF;
    border: 1px solid #D0D0D0;
}

.doc-list-title{
    color: #808080;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: normal;
}

.doc-list-data{
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background: #E6E6E6;
    margin-bottom: 0.31rem;
    display: flex;
    align-items: center;
}

.doc-list-data .text{
    color: #555;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: normal;
}

.doc-download-btn{
    width: 5.125rem;
    height: 1.5625rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #176BBE;
    color: #FFF;
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.doc-download-btn:hover{
    color: #176BBE;
    border: 1px solid #176BBE;
    background: #FFF;
}

.doc-view-btn{
    width: 3.75rem;
    height: 1.5625rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #4B00C6;
    color: #FFF;
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.doc-view-btn:hover{
    color: #4B00C6;
    border: 1px solid #4B00C6;
    background: #FFF;
}

.doc-edit-btn{
    width: 1.5625rem;
    height: 1.5625rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #3A3A3A;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.doc-edit-btn:hover{
    color: #3A3A3A;
    border: 1px solid #3A3A3A;
    background: #FFF;
}

.consultation-shift-dates{
    display: flex;
    align-items: center;
}

.consultation-dates-btn{
    margin-right: 0.62rem;
    height: 2.1875rem;
    width: 8.125rem;
    border-radius: 0.3125rem;
    box-shadow: 0px 0px 3px 0px rgba(84, 84, 84, 0.25);
    background: #FFF;
    color: #5D5D5D;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.consultation-dates-btn.active{
    background: #FAC88E;
    color: #FFF;
}

.consultation-type-control .form-check-label {
    color: #343434;
    font-size: 1rem;
    margin-left: 0.62rem;
    font-weight: 500;
    line-height: normal;
}

.consultation-type-control .form-check-input {
    width: 1.3rem !important;
    height: 1.3rem !important;
    margin-top: 0rem !important;
}

.physician-shift-container{
    /* width: 32.4375rem; */
    height: 15.625rem;
    border-radius: 0.3125rem;
    border: 1px solid #D0D0D0;
    background: #FFF;
    overflow-y: scroll;
}

.shift-remove-btn{
    height: 2.1875rem;
    width: 2.1875rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    background: #3A3A3A;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.shift-remove-btn:hover{
    color: #3A3A3A;
    border: 1px solid #3A3A3A;
    background: #FFF;
}

.physician-fee-container{
    height: 3.75rem;
    border-radius: 0.3125rem;
    border: 1px solid #D0D0D0;
    background: #FFF;
}

.physician-fee-container .text{
    font-size: 0.875rem;
    font-weight: 600;
    color: #3A3A3A;
}