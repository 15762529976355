/* Input Text With Button Style start */
.icon-input-box{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.29981rem;
    border-bottom-left-radius: 0.29981rem;
    border: 1px solid #D0D0D0;
    /* background: #ECECEC; */
    flex-shrink: 0;
    height: 2.09863rem;
}

.icon-input-box::placeholder{
    text-align: center;
    color: #343434;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
}

.icon-input-btn{
    width: 2.09863rem;
    height: 2.09863rem;
    background: #154674;
    border-radius: 0.29981rem !important;
    cursor: pointer;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-input-btn.btn{
    --bs-btn-font-size: 1.5rem;
    --bs-btn-padding-x: 0rem;
    --bs-btn-padding-y: 0rem;
    --bs-btn-color: #154674;
    --bs-btn-bg: #9d9d9d;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: #154674;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: #154674;
}

.icon-input-btn.btn:hover{
    color: #FFF !important;
    background-color: #272954 !important;
    border-color: #154674 !important;
}
/* Input Text With Button Style end */
/* Input Textbox Style start */
.input-text-control{
    border-radius: 0.3125rem !important;
    border: 1px solid #D0D0D0;
    height: 2.09863rem;
    flex-shrink: 0;
}

.input-text-control::placeholder{
    text-align: center;
    color: #343434;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
}

.input-password-control{
    border-top-left-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
    border: 1px solid #D0D0D0;
    height: 2.09863rem;
    flex-shrink: 0;
}

.input-password-control::placeholder{
    text-align: center;
    color: #343434;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
}

.input-password-eye{
    background: #fff;
    height: 2.09863rem !important;
}
/* Input Textbox Style end */