/* Input Upload Box Style start */
.input-upload-control{
    flex-shrink: 0;
    border-radius: 0.3125rem;
    border: 1px dashed #ACACAC;
    color: #5D5D5D;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-dummy-img{
    width: 2.8125rem;
    height: 2.8125rem;
    flex-shrink: 0;
}

.image-editor-container{
    padding: 1rem 1rem;
    height: 55vh;
    border: 1px solid var(--baseColor);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.image-preview-container{
    padding: 1rem;
    height: 55vh;
    border: 1px solid var(--baseColor);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    justify-content: center;
}

.image-preview-container .box {
    display: inline-block;
    box-sizing: border-box;
}

.image-preview-container .box .img-preview {
    overflow: hidden !important;
}

.crop-cancel-btn{
    width: 7.38638rem;
    height: 2.5183rem;
    flex-shrink: 0;
    border-radius: 0.9375rem;
    color: #FFF;
    background-color: #696969;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.crop-cancel-btn:hover, .crop-cancel-btn:focus{
    border: 2px solid #696969;
    color: #424242;
    background-color: #FFF;
}

.crop-submit-btn{
    width: 7.38638rem;
    height: 2.5183rem;
    flex-shrink: 0;
    border-radius: 0.9375rem;
    color: #FFF;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #cd0000;
}

.crop-submit-btn:hover, .crop-submit-btn:focus{
    color: #cd0000;
    border: 2px solid #cd0000;
    background-color: #FFF;
}
  
/* Input Upload Box Style end */