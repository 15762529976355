.apointment-info {
  color: #108be4;
  font-family: Roboto;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.apointment-name {
  color: #212121;
  font-family: Roboto;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.apointment-data tr td {
  color: #323232;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.apointment-bill-info {
  color: #505050;
  font-family: Roboto;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.doctor-info-container{
    background-color: #565656;
}
.doctor-image img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  background-color: #f4f4f4;
}
.doctor-info .info-start {
  color: #fff;
  font-family: Roboto;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6875rem;
}
.doctor-info .info-end {
  color: #fff;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.53125rem; /* 153.125% */
}

.table-head-appointment tr th {
  color: #616161 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.table-content-appointment tr th,
.table-content-appointment tr td {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}
