.login-page-container{
    background: #154674;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-form-container{
    width:25%;
    border-radius: 0.5rem;
    background: #F4F4F4;
    padding: 2rem;
}

.login-title-label{
    color: #5D5D5D;
    font-size: 1.375rem;
    font-weight: 600;
    padding: 0rem 0rem;
    text-align: center;
}