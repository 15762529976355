.appointment-id-text{
    padding-left: 0.1rem;
    margin: 0.5rem 0rem;
    color: #209FD6;
    font-size: 1rem;
    font-weight: 400;
}

.appointment-list-text{
    color: #636363;
    margin: 0.5rem 0rem;
    font-size: 1rem;
    font-weight: 400;
}

.appointment-token-text{
    padding-left: 0.3rem;
    color: #6028FF;
    margin: 0.5rem 0rem;
    font-size: 1rem;
    font-weight: 600;
}

.appointment-status-dropdown{
    width: auto;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    margin: 0.1rem 0rem;
    background-color: #F1F1F1;
    color: #3F3F3F;
    font-size: 0.875rem;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    background-image: url(../../../../../assets/pictures/icons/down-arrow-icon.png);
    background-size: auto;
    padding-left: 0.94rem;
}

.appointment-status-dropdown option{
    margin: 1rem 1rem;
}

.appointment-edit-btn{
    width: 2.25rem;
    height: 2.25rem;
    margin: 0.05rem 0rem;
    border-radius: 0.3125rem;
    background-color: #0A2278;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.appointment-edit-btn:hover{
    color: #0A2278;
    background-color: transparent;
    border: 2px solid #0A2278;
}

.appointment-del-btn{
    width: 2.25rem;
    height: 2.25rem;
    margin: 0.05rem 0rem;
    border-radius: 0.3125rem;
    background-color: #DE7C7C;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.appointment-del-btn:hover{
    color: #DE7C7C;
    background-color: transparent;
    border: 2px solid #DE7C7C;
}

.appointmentList-sub-tabpane{
    padding: 1rem 0rem;
}

.appointmentList-filter-container{
    display: flex;
    padding-left: 1.8rem;
    align-items: center;
}

.appointmentList-filter-btn{
    /* margin-right: 0.12rem; */
    height: 2.5rem;
    width: inherit;
    border-radius: 0.3125rem;
    background: #D9D9D9;
    color: #343434;
    font-size: 0.9375rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.appointmentList-filter-btn.active{
    background: #141414;
    color: #FFF;
}

.appointmenttextdata:hover{
    color: #6028FF;
    text-decoration: underline !important;
}