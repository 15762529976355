.card-container{
    margin-top: 20px;
    margin-left: -10px;
    background: #FFF;
    /* width: 1240px; */
    /* height: 420px; */
    color: #616161;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: normal;
}
.card-container1{
    margin-top: 20px;
    margin-left: -10px;
    background: #FFF;
    /* width: 1240px; */
    color: #616161;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: normal;
}

.row-container{
    padding: 20px;
}

/* Patient Symptoms List Component Style start */
.patient-symptoms-container{
    border-radius: 0.3125rem;
    background: transparent;
    padding: 0.62rem 0rem 0.62rem 0rem;
}

.patient-symptoms-content{
    background: #BDBDBD;
    border-radius: 0.3125rem;
    height: 13.5rem;
    padding: 0.63rem 0rem 0.63rem 0.23rem;
}

.patient-symptoms-content .content.col{
    /* background: #FFF; */
    padding: 0.2rem 0.62rem 0.81rem 0.42rem; 
    flex: 0 0 auto !important;
    max-height: 13rem;
    width: 100% !important;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.patient-symptoms-content .buttons.col{
    padding-left: 0.5rem !important;
    padding-right: 0rem !important;
    display:flex;
    justify-content: end;
    flex-direction: column;
    flex: 0 0 auto !important;
    width: 8.33333333% !important;
}

.patient-symptoms-data{
    background: #FFF;
    width: auto;
    min-height: 2.5rem;
    padding: 0rem 0rem 0rem 0rem;
    margin-bottom: 0.63rem;
    display: flex;
    align-items: center;
    /* margin-top: 0.37rem; */
    border-radius: 0.3125rem;
}

.patient-symptoms-data .text{
    padding-left: 1.06rem;
    color: #5D5D5D;
    font-size: 0.87948rem;
    font-weight: 400;
    flex-wrap: wrap;
    line-height: normal;
}

.patient-symptoms-data .remove{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: normal;
    transform: rotate(-45deg);
    cursor: pointer;
}

.patient-symptoms-input{
    padding: 0.8rem 0rem 0.8rem 0.88rem !important;
    border-radius: 0.3125rem;
    resize: none;
    background: #FFF;
    font-size: 0.87948rem;
    font-weight: 400;
    width: 100%;
    padding: .5em 0;
    border: none;
    outline: none;
    margin-bottom: 0.63rem;
}

.patient-symptoms-input::placeholder{
    color: #5D5D5D;
    font-size: 0.83944rem;
    font-weight: 500;
    line-height: normal;
}
/* Patient Symptoms List Component Style end */

.buttonview{
  width: 4.875rem;
  height: 2.4rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#4c4c4c;
  color: white;
}

.buttonview:hover{
  background-color:#7d7d7d;
}

.secondary-background textarea{
    background-color:#BDBDBD;

}


.fixed {
    position: fixed;
    bottom: 0;
    width:80.85%;
    background-color: #ffffff;
    border: 2px solid #c0c0c0;
    height: 5rem;
    transition: .5s;
    z-index: 10;
    padding: 0;
    margin-left: 1.25rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.fixed.popup{
    height: 25.5rem;
    
}
.fixed .content{
    display:flex;
    align-items:center;
    justify-content: space-between;
}

.bottom_content{ 
    position: relative ;
    height: 4.8rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.bottom_content_btn{
    position: absolute;
    right: 0;
    top:-2.3rem;
    width: 5.8125rem;
    height: 2.5625rem;

}

.custom_input{
    height: 2.8125rem;
    border-radius: 0.312rem;
    border: 3px solid #ffffff;
    background-color: #D6D6D6;  
}

.accordion1{
    border-left: 1px solid black;
    font-size:medium;
}

.accordion-item1 {
    padding-left:5px ;
    cursor: pointer;
    border: #ffffff;
    position: relative;
}

.accordion-content1{
    border-left:1px solid black;
    background-color: #ffffff;
}
.accordion-img1{
    position:absolute;
    top: 0;
    left:-1.75rem;
}

.presc-table td{
    height: 2.8125rem;
    border-radius: 0.5rem;
    border: 4px solid #EAEAEA;
    background-color: white;  
}

/* .presc-table td:first-child{
    height: 2.8125rem;
    border-radius: 0px;
    border: 0px;
    background-color: transparent;  
} */

.present1{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 20rem;
}

.presc-table{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 16.9rem;
}

.presc-table th{
    height: 2.8125rem;
    background-color: #EAEAEA;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.symp {
    height: 2.8125rem;
    border-radius: 0.5rem;
    border: 3px solid #EAEAEA;
    display:flex;
    align-items: center;
}
.symp-row{
    overflow-y: scroll;
    overflow-x: hidden;
    /* height: 20rem; */
}

.invest {
    height: 2.8125rem;
    border-radius: 0.5rem;
    border: 3px solid #EAEAEA;
    display:flex;
    align-items: center;
    justify-content: center; 
}

.invest-test {
    height: 2.8125rem;
    border-radius: 0.5rem;
    border: 3px solid #EAEAEA;
    display:flex;
    align-items: center;
}

.invest-remark{
    height: 4.5rem;
    border-radius: 0.5rem;
    border: 3px solid #EAEAEA;
    overflow-y: scroll;
    overflow-x: hidden;
}

.invest-row{
    overflow-y: scroll;
    overflow-x: hidden;
    /* height: 20rem; */
}


.assessment-main1{
    background: #343434;
    border-radius: 0.3125rem;
    padding: .75rem 1rem .75rem 1rem !important;    
    color: #FFF;
    font-size: 0.8125rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .assessment-sub1{
    background: #FFB1B1;
    border-radius: 0.3125rem;
    padding: .75rem 1rem .75rem 1rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .assessment-desc1{
    background: #FFF;
    border-radius: 0.3125rem;
    padding: .75rem 1rem .75rem 1rem !important;
  }

  .assessment-sub2{
    background: #A1D7FF;
    border-radius: 0.3125rem;
    padding: .75rem 1rem .75rem 1rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .assessment-row{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 20rem;
  }

  .testrepo{
    width: 100%;
    height: 11rem;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 1rem;
    padding-bottom: .5rem;
  }

  .subashta{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 20rem;
  }