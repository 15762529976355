.img-container{
    width: 86px;
    aspect-ratio: 1/1;
    background-color: #e7e5ff;
}

.text-main{

    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400; 
}

.text-sub{
    
font-size: 1.4375rem;
font-style: normal;
font-weight: 700; 
}