.bgc{
    background-color: #154674;
}
.nh-input{
    border: 2px rgb(230, 230, 230) solid;
}
.nh-input:focus{
    border: 2px rgb(180, 180, 255) solid;
}

