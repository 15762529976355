/* layout css start */
.window-container{
    background-color: #FFFFFF;
}
/* .body-container{} */
/* layout css end */

/* page css start */
.page-container{
    background-color: #FFFFFF;
}
/* page css end */