/* Dropdown Box Style start */
.custom-dropdown .ui.selection.dropdown{
    min-height: 0 !important;
    height: 2.09863em;
    padding: .48571429em 1.5em .48571429em 1em;
}

.custom-dropdown .ui.selection.dropdown > input.search{
    padding: .48571429em 1.5em .48571429em 1em !important; 
}

.custom-dropdown .ui.selection.dropdown > .delete.icon, 
.custom-dropdown .ui.selection.dropdown > .dropdown.icon, 
.custom-dropdown .ui.selection.dropdown > .search.icon{
    padding: .51666667em;
}

.custom-dropdown .ui.selection.dropdown .menu > .item{
    padding: .48571429em 1.14285714em !important;
}