/* breadcrumb start */
.breadcrumb{
    display: flex;
    align-items: center;
}

.breadcrumb-item{
    display: flex;
    align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
    content:">";
    font-size: 0.8125rem;    
}

.breadcrumb-item a{
    color: #5D5D5D !important;
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: normal;
}

.breadcrumb-item.active{
    color: #5D5D5D !important;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: normal;
}

.breadcrumb-list{
    padding-left: 1rem;
}

.breadcrumb-list ol{
    margin-bottom: 0rem !important;
}
/* breadcrumb end */